@import "/src/themes/origin-inca-theme";

.header {
  /* height: 64.5px; */
  height: 4.04rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  height: $theme-content-top-bar-height;
  line-height: $theme-content-top-bar-height;
  button {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
    margin: 0.5rem 0.5rem;
    color: #158f76;
    cursor: pointer;

    &:hover {
      background-color: #1ab394;
      color: #e8f7f4;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      .header-icon {
        color: #e8f7f4;
      }
    }
    .header-icon {
      font-size: 1.5rem;
      color: #b6b9b8;
      margin: 0.5rem 0;
    }
  }
}

.error {
  text-align: center;
  /* background-color: #f7e8eb; */
  /* border: 1px solid #b31a39; */

  margin: 0;
  padding: 1rem;
  color: #444545;
  font-size: 14px;
}

.error svg {
  margin-right: 0.3rem;
}

.analytics-container {
  display: flex;
  height: 100%;
}

.analytics {
  flex: 1;
}

aside.sidebar {
  width: 0px;
  overflow: hidden;
  height: auto;
  // min-height: 100%;
  height: 100%;

  // outline: 2px dashed blueviolet;
}

aside.sidebar.open {
  width: 350px;
}
