.upload-file {
}

.upload-file-form {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.form-actions {
  flex-direction: row !important;
  margin-top: 1rem;
}
