// Copyright 2010 Google LLC
// Author: username

.permission-table {
  //   background-color: fuchsia;
}

.datagrid {
  // outline: thin dashed fuchsia;
  height: calc(100vh - 650px);
  overflow-x: hidden;
}

.datagrid-header {
  display: flex;
  justify-content: space-between;

  align-items: center;
}
