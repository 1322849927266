@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Viaoda+Libre&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "/src/styles/theme";
@import "/src/styles/textbox";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $token-font-family-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $token-font-weight-regular;
  font-size: 16px;
  background-color: #e3e7e6;
}
button {
  font-family: "Lato", "Segoe UI", "Open Sans", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system,
    BlinkMacSystemFont, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.report-style-class {
  height: 100%;
}

.report-style-class iframe {
  border: none;
  border: 1px solid #e3e7e6;
}

#root > header.screen-header {
  border-top-color: $token-color-surface;
  padding-left: 0 !important;
  a:hover,
  button:hover {
    color: $token-color-text-primary;
  }
}

.logo {
  > a {
    background-color: $token-color-surface;
    > span {
      line-height: $token-font-line-height-2;
    }
  }
}

.button.primary {
  background-color: $token-color-surface;
}

.split-view {
  .aside-view {
    button {
      &:active,
      &:focus {
        outline-color: $token-color-action-primary;
      }
      &:hover {
        background-color: $token-color-action-primary;
        color: $token-color-text-on-primary;
        svg {
          color: $token-color-text-on-primary;
        }
      }
      svg {
        color: $token-color-action-primary;
        // &:hover {
        //   color: $token-color-text-on-primary;
        // }
      }
    }
  }
}

.administration-menu {
  li {
    a {
      color: $token-color-text;
      &.active {
        color: $token-color-text-primary;
      }
    }
  }
  > li {
    color: $token-color-text;
    &:hover {
      background-color: $token-color-surface;
    }
  }
}

.vertical-menu {
  > li {
    color: $token-color-text;
    > header {
      border: 1px solid #e3e7e6;
      &:hover {
        background-color: $token-color-surface;
      }
    }
    > ul {
      color: $token-color-text;
      > li {
        background-color: $token-color-surface-highlight-subdued;
        border-bottom: 1px solid #e3e7e6;
        &:hover {
          background-color: $token-color-surface;
          > a,
          > .icon {
            color: $token-color-text-on-primary;
          }
          > a.active {
            color: $token-color-text-on-primary;
          }
        }
        > a {
          color: $token-color-text;
          &.active {
            color: $token-color-text-primary;
          }
        }
      }
    }
  }
}

.card-menu-card {
  &:hover {
    border-color: $token-color-border-highlight;
    a {
      color: $token-color-text-primary;
    }
  }

  h3 {
    color: $token-color-text-primary;
  }
}

.insightRightColumn {
  background-color: $token-color-surface-highlight-subdued;
  min-height: calc(100vh - 228px);
  .data-referen-toc {
    background-color: $token-color-surface-highlight-subdued;
    header {
      h2 {
        color: $token-color-text-primary;
      }
    }
    ul {
      li {
        a {
          color: $token-color-text-primary;
        }
      }
    }
  }
}

header.insights {
  h3 {
    color: $token-color-text;
    button {
      color: $token-color-text-primary !important;
      &:active,
      &:focus {
        outline-color: $token-color-border-highlight;
      }
      &:hover {
        background-color: $token-color-action-primary !important;
        color: $token-color-text-on-primary !important;
      }
    }
  }
}

.insights-view {
  button {
    color: $token-color-text-primary !important;
    &:active,
    &:focus {
      outline-color: $token-color-border-highlight;
    }
    &:hover {
      background-color: $token-color-action-primary !important;
      color: $token-color-text-on-primary !important;
    }
  }
}

.comment-box {
  border: 1px solid $token-color-border;
  font-family: $token-font-family-sans;
  font-size: $token-font-size-100;
  color: $token-color-text;
  header {
    border: 1px solid $token-color-border;
    h2 {
      font-size: $token-font-size-400;
      font-weight: $token-font-weight-semibold;
    }
  }
  form {
    label {
      textarea {
        font-family: $token-font-family-sans;
        border-left: 5px solid $token-color-surface;
        &:active,
        &:focus {
          outline: 1px solid $token-color-border-highlight;
        }
      }
    }
    footer {
      border-top: 1px solid $token-color-border;
      border-bottom: 1px solid $token-color-border;
      background-color: $token-color-surface-subdued;

      div {
        button {
          color: $token-color-text;
          &:hover {
            color: $token-color-action-primary;
          }
        }
      }
      > button {
        background-color: $token-color-action-primary;
        color: $token-color-text-on-primary;
        &:disabled {
          color: $token-color-text-primary;
          background-color: $token-color-surface-highlight-subdued;
          border: 1px solid $token-color-border;
        }
      }
    }
  }
  .comment-box-content {
    background-color: $token-color-background;

    .comment-thread {
      border: 1px solid $token-color-border;
      .comment {
        &:nth-child(even) {
          background-color: $token-color-surface-neutral-subdued;
        }
        &:nth-child(odd) {
          background-color: white;
        }
      }
      footer {
        border-top: 1px solid $token-color-border;
        background-color: $token-color-surface-subdued;

        &.replying {
          border-top: 1px solid $token-color-border-highlight;
          border-bottom: 1px solid $token-color-border-highlight;
          border-right: 1px solid $token-color-border-highlight;

          button {
            color: $token-color-action-primary;
            &:hover {
              color: $token-color-action-primary;
            }
          }

          input {
            &:active,
            &:focus {
              border-left-color: $token-color-action-primary;
            }
          }
        }

        &.error {
          border: 1px solid $token-color-border-critical;
          button {
            color: $token-color-action-critical;
            &:hover {
              color: $token-color-action-primary;
            }
          }
          input {
            border: 1px solid $token-color-border-critical;
          }
        }

        input {
          border: 1px solid $token-color-border-highlight;
        }
        button {
          &:hover {
            color: $token-color-text-primary;
          }
        }
      }
    }
  }
}

.dialog {
  > header {
    .dialog-close {
      color: $token-color-action-primary;
      cursor: pointer;
    }
  }
  p {
    margin: $token-spacing-space-1 0;
  }
}

/**
 * Forms:
 * 
 */

.search {
  display: flex;
  flex-direction: column;
  margin-bottom: $token-spacing-space-1;
  label {
    margin-bottom: $token-spacing-space-1;
    font-size: $token-font-size-100;
  }
  input[type="text"] {
    @include textbox($token-color-border, $token-color-action-primary);
    width: 300px;
    &::placeholder {
      color: $token-color-text-disabled;
      font-style: italic;
    }
    & + svg {
      color: $token-color-icon;
    }
  }
}

.form-group {
  // outline: thin dashed red;

  display: flex;
  align-items: center;
  margin-bottom: $token-spacing-space-1;
  label {
    font-size: $token-font-size-100;
    flex-basis: 5%;
    min-width: 100px;
  }

  input,
  select {
    flex-basis: 25%;
    min-width: 240px;
    margin: 0 $token-spacing-space-1;
    @include textbox($token-color-border, $token-color-action-primary);

    &.error {
      border-color: $token-color-border-critical;
    }
  }
  .error-message {
    margin-left: $token-spacing-space-1;
    color: $token-color-text-critical;
    font-size: $token-font-size-100;
  }
}

.datagrid-action {
  color: $token-color-action-primary;
  font-size: $token-font-size-100;
  border: thin solid $token-color-border;

  width: $token-spacing-space-8;
  height: $token-spacing-space-8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  margin: 4px 0 0 0;
}

input[type="checkbox"]:checked + .rdg-checkbox {
  background-color: $token-color-action-primary !important;
}

.rdg-checkbox {
  border-width: 1px !important;
  border-color: $token-color-border !important;
}

.rdg {
  margin-bottom: $token-spacing-space-1;
  .avatar {
    span {
      font-size: $token-font-size-700 + 1.5rem;
    }
    margin: 4px 0 0 12px;
  }
}

.form-section {
  margin-top: $token-spacing-space-4;
  margin-bottom: $token-spacing-space-4;
  h4 {
    margin: 0 0 $token-spacing-space-3;
    font-size: $token-font-size-200;
  }
}

.form-actions {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: $token-spacing-space-4;
  > * {
    margin-left: $token-spacing-space-2;
  }
}

.screen-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $token-color-border;
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  h3 {
    font-size: 1rem;
    font-weight: 600;
  }
}

.animation-spinner {
  animation: 1.5s alternate infinite spinner;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
