@mixin textbox(
  $border-color: fuchsia,
  $acent-color: black,
  $shadow-color: rgba(0, 0, 0, 0.05)
) {
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  border: 1px solid $border-color;
  box-shadow: 0 3px 6px $shadow-color;

  &:active,
  &:focus {
    outline: 1px solid $acent-color;
  }
}
