// Copyright 2010 Google LLC
// Author: username

.data-referen-toc {
  background-color: #e8f7f4;
  padding-bottom: 1rem;
  padding-right: 1rem;

  header {
    padding: 0.5rem 0;
    // outline: thin dashed red;
    h2 {
      margin: 1rem;
      color: #444545;
      //   outline: thin dashed red;
    }
  }

  ul {
    // outline: thin dashed red;
    padding: 0;
    margin-left: 1rem;

    li {
      list-style: none;
      margin-bottom: 2px;

      a {
        color: #1ab394;
        text-decoration: none;
        font-size: 14px;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
