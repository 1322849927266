.card {
  min-width: 350px;
  max-height: 130px;
  height: 110px;
  flex: 0 0 400px;
  margin: 0 0 1rem 1rem;
  border: 1px solid #e3e7e6;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.card:hover {
  border: 1px solid #31bb9f;
}

@media screen and (max-width: 768px) {
  .card {
    flex: 1 0 80%;
    min-width: 80%;
  }
}

.card h3 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0.5rem 0 01rem 0;
  color: #1ab394;
  font-size: 18px;
}

.card p {
  margin: 0 0 1rem 0;
  overflow: hidden;
  font-size: 14px;
}

.menu-category {
  width: 100%;
  margin: 0 1rem 1rem 1rem;
}

.card-link {
  border-radius: 4px;
  padding: 0.5rem 1rem;

  display: flex;
  flex-flow: column;
  text-decoration: none;
  color: #444545;
  cursor: pointer;
  min-height: 100%;
}

.card-link:hover {
  background-color: #fff;
  /* background-color: #1ab394; */
  color: #31bb9f;
}

/* styles for '...' */
.block-with-text {
  /* outline: thin solid red; */
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2.4em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.block-with-text:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
  background-color: transparent;
}
/* hide ... if we have text, which is less than or equal to max lines */
.block-with-text:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

.card-link:hover .block-with-text:after {
  background: #f5f5f5;
  background: #fff;
}
