// Copyright 2010 Google LLC
// Author: username

.split-view {
  display: flex;
  &.debug {
    outline: thin dashed fuchsia;

    .split-view-pane {
      outline: thin dashed fuchsia;
    }
  }
  .split-view-pane {
    &.split-view-pane-left {
    }
    &.split-view-pane-right {
      flex-shrink: 0;
    }
  }
}
