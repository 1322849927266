.vertical-menu-item {
}

.vertical-menu-item > ul {
  transition: all 0.2s ease-out;
  height: auto;
  overflow: hidden;
}

.collapsed {
  height: 0;
  max-height: 0px;
}

.vertical-menu-item > .icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
