// Copyright 2010 Google LLC
// Author: Alex Alvarez Gárciga

@import "/src/themes/origin-inca-theme";
@import "/src/styles/textbox";

.dropdown {
  @include textbox($theme-color-control-border, $theme-color-primary);
}

.dropdown {
  border-radius: 4px;
  background-color: $theme-color-user-input;
  margin-bottom: 24px;
}

.dropdown-error {
  color: $theme-color-error;
  margin-bottom: 0;
  margin-top: -16px;
}
