.navigation {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  color: #444545;
  align-items: center;
  padding-right: 0.5rem;
  text-align: right;
  justify-content: flex-end;
  //   outline: thin solid red;
  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
  a {
    // outline: thin dashed fuchsia;
    font-size: 14px;
    text-decoration: none;
    color: #444545;
    flex-wrap: nowrap;
    white-space: nowrap;
    // margin: 0 8px 0 0;
    margin: 0 0 0 8px;
    line-height: 16px;

    border: none;
    /* background-color: #1ab394; */
    background-color: #fff;
    padding: 8px 0.5rem;
    border-radius: 4px;
    /* border: 1px solid #e3e7e6; */
    cursor: pointer;

    > span {
      margin-left: 0.5rem;
    }

    &:hover {
      /* background-color: #158f76; */
      border-color: #158f76;
      color: #158f76;
      span {
        color: #444545;
      }
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  button {
    // outline: thin dashed fuchsia;
    box-sizing: content-box;
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    color: #444545;
    border: none;
    /* border: 1px solid #e3e7e6; */
    background-color: #1ab394;
    background-color: #fff;
    padding: 8px 0.5rem;
    border-radius: 4px;
    /* font-size: 1rem; */
    white-space: nowrap;
    cursor: pointer;
    margin: 0 0 0 8px;

    @media screen and (max-width: 768px) {
      border: none;
      text-align: left;
      //   border-radius: 0;
    }

    &:hover {
      /* background-color: #158f76; */
      border-color: #158f76;
      color: #158f76;
      span {
        color: #444545;
      }
    }

    span {
      margin-left: 0.5rem;
    }
  }

  .navigation-entries {
    display: flex;
    align-items: center;
    text-align: left;

    > span {
      // outline: thin dashed fuchsia;
      margin: 0 0.5rem 0 1.5rem;
      border-left: 1px solid #444545;
      padding: 8px;
      padding-left: 1rem;
      line-height: 16px;
      display: inline-block;

      @media screen and (max-width: 768px) {
        border: none;
        margin: 0;
        padding: 8px 1rem;
        border-bottom: 1px solid #e3e7e6;
      }

      span {
        margin-left: 0.5rem;
      }
    }
    @media screen and (max-width: 768px) {
      display: none;
      min-width: 200px;
      position: absolute;
      left: 16px;
      right: 16px;
      top: 50px;
      margin: 0;
      z-index: 3;
      flex-direction: column;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #e3e7e6;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    }

    &.expanded {
      @media screen and (max-width: 768px) {
        display: flex;
        align-items: stretch;
      }
    }

    a {
      @media screen and (max-width: 768px) {
        color: #444545;
        margin: 0;
        padding: 8px 16px;
        border: none;
        border-bottom: 1px solid #e3e7e6;

        background-color: transparent;
        padding: 8px 1rem;
        border-radius: 0;
        margin: 0;

        &:hover {
          background-color: #f1f3f3;
        }

        &:first-of-type {
          margin: 0;
          border-radius: 4px 4px 0 0;
        }

        &:last-of-type {
          border-bottom: none;
          border-bottom: 1px solid #e3e7e6;
        }

        & > span {
          margin: 0 0 0 0.5rem;
        }
      }
    }

    button {
      @media screen and (max-width: 768px) {
        color: #444545;
        margin: 0;
        padding: 8px 16px;
        border: none;
        &:hover {
          background-color: #f1f3f3;
        }
      }
    }
  }

  .hamburger {
    display: none;
    font-size: 1.5rem;
    color: #158f76;
    margin-right: 0.5rem;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
