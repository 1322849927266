// Copyright 2010 Google LLC
// Author: Alex Alvarez Garciga.

.logo {
  border-bottom: 1px solid #1ab394;
  background: #1ab394;
  margin: 0 1rem 0 0;
  text-decoration: none;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;

    /*
      Todo: for Curazao remove the margin on the img
    */
    margin-left: 8px;
  }
  > span {
    /*font-family: "MuseoModerno", cursive;*/
    font-size: 1rem;
    font-weight: 500;
    color: #e8f7f4;
    /* color: #444545; */
    margin: 0;
    max-width: 150px;
    /* border-left: 1px solid #444545; */
    border-left: 1px solid #e8f7f4;
    padding-left: 10px;
  }
}
