// Copyright 2010 Google LLC
// Author: username

.dialog {
  //   outline: thin dashed fuchsia;
  position: fixed;
  z-index: 2;
  border: 1px solid #eee;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  text-align: center;
  box-sizing: border-box;
  top: 30%;
  left: 25%;
  width: 50%;
  border-radius: 8px;

  display: none;

  &.open {
    display: block;
  }
  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #e3e7e6;
    padding: 0 0 0 32px;
    > h2 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      color: #444545;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }
    .dialog-close {
      margin: 16px;
      font-size: 2rem;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
      border: 1px solid #e8f7f4;
      background-color: #e8f7f4;
      color: #1ab394;
    }
  }
  .dialog-body {
    text-align: left;
    padding: 0 32px;
    font-size: 1rem;
    font-weight: 400;
    color: #444545;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  > footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #e3e7e6;
    padding: 16px 16px 16px 32px;
    > button + button {
      margin-left: 8px;
    }
  }
}
