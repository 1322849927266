.header {
  display: flex;
  padding: 0;
  /* background-color: #1ab394; */

  border-top: 4px solid #1ab394;
  /* border-bottom: 1px solid #e3e7e6; */
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 5%);
  font-size: 0.8rem;
}

.header + main {
  background-color: #e3e7e6;
  padding: 0.5rem;
}

.header > *:first-child {
  width: 308px;
}

.header + main > * {
  background: #fff;
  border-radius: 4px;
  min-height: calc(100vh - 75px);
}
