.dashboard {
  display: flex;
  flex-direction: column;
}

.dashboard > header {
  height: 250px;
  display: flex;
  flex-flow: column;
  padding: 0 1rem;
  color: #444545;
  flex: 0;
  background: #eeebf1 url("/src/assets/images/insights-banner.png") no-repeat
    top right;
  /* background-size: 100% auto; */
  border-radius: 4px 4px 0 0;
}

@media screen and (max-width: 768px) {
  .dashboard > header {
    background: url("/src/assets/images/insights-banner.png") no-repeat 88%
      bottom;
    background-size: auto 100%;
  }
}

.dashboard > header h2 {
  /* background-color: #1ab394; */
  display: flex;
  margin: 2rem 0 1rem 0;
}

.dashboard header p {
  width: 60%;
  font-size: 14px;
  margin: 0.25rem 0;
}

.dashboard header p:last-of-type {
  margin-bottom: 2rem;
}

.data-references {
  height: 200px;
  background-color: #e8f7f4;
}

.data-references header h2 {
  margin: 1rem;
  color: #444545;
}

.data-references ul {
  padding: 0;
  margin-left: 1rem;
}

.data-references li {
  list-style: none;
  margin-bottom: 2px;
}

.data-references a {
  color: #1ab394;
  text-decoration: none;
  font-size: 14px;
}
.data-references a svg {
  margin-right: 0.5rem;
}

.data-references a:hover svg {
  color: #444545;
}
