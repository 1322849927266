.page404 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #444545;
}

.page404 > div {
  margin-left: -10%;
  width: 100%;
  max-width: 500px;
  text-align: left;

  background: #fff;
  padding: 1rem 2rem;
  border: 1px solid #e3e7e6;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.01);
}

.page404 h1 {
  color: #1ab394;

  margin: 1rem 0 2rem 0;
}

.page404 h1 svg {
}

.page404 a {
  color: #1ab394;
  text-decoration: none;
}

.page404 > svg {
  width: 40%;
  height: 100%;
  color: #ccd0cf;
  margin-left: -10%;
  z-index: -1;
}
