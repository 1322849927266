// Copyright 2010 Google LLC
// Author: Alex Alvarez Gárciga

@import "/src/themes/origin-inca-theme";
@import "/src/styles/icon-button";

.aside-view {
  //   outline: thin dashed fuchsia;

  > header {
    display: flex;
    align-items: center;
    padding: 0 4px;
    height: $theme-content-top-bar-height;
    line-height: $theme-content-top-bar-height;
    border-bottom: 1px solid $theme-color-control-border;
    // outline: thin dashed fuchsia;
    h3 {
      font-size: 1.2rem;
      font-weight: 100;
      color: $theme-color-text;
      margin: 0.5rem 0;
    }

    button {
      @include icon-button($theme-color-primary);
    }
  }
  &.collapsed {
    > header {
      justify-content: center;
    }
  }
}

.aside-view-content {
  //   outline: thin dashed fuchsia;
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.05);
  &.aside-collapsed {
  }
}
