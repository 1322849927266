// Copyright 2010 Google LLC
// Author: Alex Alvarez Gárciga

@import "/src/themes/origin-inca-theme";
@import "/src/styles/textbox";

.user-form {
  // background-color: fuchsia;
}

.detail {
  border: thin solid $theme-color-control-border;
  padding: 1rem;
  border-radius: 4px;
}

.form-actions {
}

.delete {
  background-color: $theme-color-user-input;
}
