@import "/src/styles/screen-content-header";
@import "/src/styles/screen-content";

.screen-header {
  @include screen-content-header;
}

.screen-content {
  @include screen-content;
  padding-top: 1rem;
}

.datagrid {
  // outline: thin dashed fuchsia;
  height: calc(100vh - 287px);
}

.datagrid-header {
  display: flex;
  justify-content: space-between;

  align-items: center;
}
