@import "/src/styles/colors";

$theme-color-background: $color-chinese-black !default;
$theme-color-primary: $color-jungle-green !default;

$theme-dark-text: $color-jungle-green-tint-90 !default;
$theme-color-error: $color-red-NCS !default;

$theme-color-control-border: $color-platinum !default;
$theme-color-control-light-background: $color-platinum-tint-80 !default;
$theme-color-control-placeholder: $color-platinum-shade-10 !default;

$theme-color-user-input: $color-rajah-tint-90 !default;
$theme-color-text: $color-platinum-shade-70 !default;

$theme-content-top-bar-height: 56px;

/*
 * Theme definition
 */

// $token-color-action-critical: $theme-color-error;

$token-color-border: $color-platinum;
$token-color-surface: $color-jungle-green;
$token-color-action-primary: $color-jungle-green;
$token-color-text-primary: $color-jungle-green;
$token-color-text-on-primary: $color-jungle-green-tint-90;
$token-color-border-critical: $color-red-NCS;
$token-color-text-critical: $color-red-NCS;
$token-color-text-disabled: $color-platinum-shade-10;

$token-color-border-highlight: $color-jungle-green;
$token-color-surface-highlight-subdued: $color-jungle-green-tint-90;
