// Copyright 2010 Google LLC
// Author: Alex Alvarez Gárciga

.avatar {
  overflow: hidden;
  &.dark {
    color: white;
  }

  &.light {
    color: black;
  }
  //   outline: thin dashed fuchsia;
  > img {
    // outline: thin dashed fuchsia;
    // display: block;
  }
  > span {
    // outline: thin dashed fuchsia;
    display: inline-block;
  }
}
