// Copyright 2010 Google LLC
// Author: Alex Alvarez Gárciga

@import "/src/themes/origin-inca-theme";

.button {
  border: 0;
  cursor: pointer;
  line-height: 1;
  font-weight: 700;
  border-radius: 4px;

  &.primary {
    color: $theme-dark-text;
    background-color: $theme-color-primary;
    &.dark {
      color: $theme-color-background;
    }
  }
  &.secondary {
    color: $theme-color-background;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
    &.dark {
      border: 1px solid $theme-color-primary;
      color: $theme-color-primary;
      background-color: transparent;
    }
  }
  &.small {
    font-size: 12px;
    padding: 6px 16px;
  }

  &.medium {
    font-size: 14px;
    padding: 9px 16px;
  }
  &.large {
    font-size: 16px;
    padding: 12px 24px;
  }
}
