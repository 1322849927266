@import "/src/styles/colors";

@mixin icon-button($color: fuchsia) {
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;

  margin: 0.5rem 0.5rem;
  color: $color;
  cursor: pointer;
  &:active,
  &:focus {
    outline: 1px dashed $color;
  }
  &:hover {
    background-color: $color;
    color: #e8f7f4;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  }
}
