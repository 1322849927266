// Copyright 2010 Google LLC
// Author: username

.search {
  div {
    display: flex;
    align-items: center;

    input[type="text"] {
      & + svg {
        margin-left: -16px;
        margin-top: 16px;
        animation: 1.5s alternate infinite spinner;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
