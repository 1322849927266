.header {
  display: flex;
  align-items: center;
  padding: 0 4px;
  height: 56px;
  line-height: 56px;
}
.header.collapsed {
  justify-content: center;
}

.header h3 {
  font-size: 1.2rem;
  font-weight: 100;
  color: #444545;
  margin: 0.5rem 0;
}

.header .button {
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;

  margin: 0.5rem 0.5rem;
  color: #158f76;
  /* background-color: #1ab394; */
  cursor: pointer;
}
.header .button:active,
.header .button:focus {
  outline: 1px dashed #158f76;
}

.header .button:hover {
  background-color: #1ab394;
  color: #e8f7f4;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.left-pane {
  height: 100%;
  min-height: calc(100vh - 75px);
  text-align: center;
  /* outline: thin dashed fuchsia; */
}

.right-pane {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.05);
  height: calc(100vh - 75px);
  padding-bottom: 66px;
  background-color: #fff;
  /* outline: 2px dashed fuchsia; */
}

.expanded {
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}
