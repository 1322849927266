$color-text: #444545;
$color-acent: #1ab394;
$color-acent-tint: #e8f7f4;
$color-border: #e3e7e6;
$color-footer: #f9fafa;
$color-background: #f1f3f3;

.comment-box {
  // border: 1px solid $color-border;
  //font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  // font-size: 14px;
  // color: $color-text;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 100%;

  header {
    padding: 32px;
    border-bottom: 1px solid $color-border;
    h2 {
      font-size: 24px;
      font-weight: 400;
      margin: 0;
      svg {
        margin-right: 8px;
      }
    }
    h3 {
      margin: 8px 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
  form {
    display: flex;
    flex-flow: column;
    label {
      flex: 1;
      display: flex;
      textarea {
        box-sizing: border-box;
        padding: 16px 16px 8px 27px;
        font-size: 14px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        flex: 1;
        height: 78px;
        border: none;
        border-left: 5px solid $color-acent;
        &:active,
        &:focus {
          outline: 1px solid $color-acent;
        }
      }
    }
    footer {
      margin-top: 1px;
      border-top: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
      background-color: $color-footer;
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        font-size: 16px;
        padding-left: 27px;
        button {
          font-size: 14px;
          background: none;
          border: none;
          color: $color-text;
          cursor: pointer;
          &:hover {
            color: $color-acent;
          }

          svg {
            margin-right: 8px;
          }
        }
      }
      > button {
        background-color: $color-acent;
        color: $color-acent-tint;
        border: none;
        padding: 0 32px;
        &:disabled {
          color: $color-acent;
          background-color: $color-acent-tint;
          border: 1px solid $color-border;
        }
      }
    }
  }
  .comment-box-content {
    background-color: $color-background;
    padding: 8px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: auto;

    > * {
      margin-bottom: 8px;
    }
  }
}
