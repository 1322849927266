@mixin flex-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
}

$color-text: #444545;
$color-text-dimmed: #9fa2a1;

.comment {
  display: flex;
  align-items: flex-start;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: $color-text;
  //   outline: thin dashed fuchsia;
  .avatar {
    // outline: thin dashed fuchsia;
    // border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 8px;
    background-color: #444545;
  }
  > div {
    // outline: thin dashed fuchsia;
    display: flex;
    flex-flow: column;
    flex: 1 0;
    overflow: hidden;

    .comment-author {
      overflow: hidden;
      //   outline: thin dashed fuchsia;
      margin: 8px 0 0 0;
      font-weight: 600;
      @include flex-text-overflow();
    }

    .comment-date-history {
      //   outline: thin dashed fuchsia;
      margin: 0;
      color: $color-text-dimmed;
    }
    .comment-content {
      //   outline: thin dashed fuchsia;
      margin: 8px 0;
      padding-right: 1rem;
      // text-align: justify;
    }
  }
}
