.screen-header {
  //   outline: thin dashed red;
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  overflow: hidden;

  .logo {
    // outline: thin dashed fuchsia;
  }

  .navigation {
    // outline: thin dashed fuchsia;
  }
}
