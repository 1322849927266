$color-border: #e3e7e6;
$color-footer: #f9fafa;
$color-acent: #1ab394;
$color-acent-text: #e8f7f4;
$color-acent-dark: #17a185;
$color-error: #b31a39;
$color-error-text: #f7e8eb;

.comment-thread {
  border: 1px solid $color-border;
  .comment {
    &:nth-child(even) {
      background-color: $color-footer;
    }
    &:nth-child(odd) {
      background-color: white;
    }
  }
  footer {
    border-top: 1px solid $color-border;
    border-bottom: 1px solid transparent;

    background-color: $color-footer;
    min-height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: flex-end;
    &.replying {
      justify-content: space-between;
      border-top: 1px solid $color-acent;
      border-bottom: 1px solid $color-acent;
      border-right: 1px solid $color-acent;

      button {
        color: $color-acent;
        &:hover {
          color: $color-acent;
        }
      }

      input {
        &:active,
        &:focus {
          border-left-color: $color-acent-dark;
        }
      }
    }

    &.error {
      border: 1px solid $color-error;
      background-color: $color-footer;
      button {
        color: $color-error;
        &:hover {
          color: $color-acent;
        }
      }
      input {
        border: 1px solid $color-error;
        border-left-width: 5px;
      }
    }

    input {
      margin-top: -1px;
      margin-bottom: -1px;
      border: 1px solid $color-acent;
      border-left-width: 5px;

      margin-right: 0.5rem;
      flex: 1;
      padding: 0 0.5rem 0 1rem;
      &:active,
      &:focus {
        outline: none;
      }
    }
    button {
      border: none;
      background: none;
      margin-right: 8px;

      cursor: pointer;
      &:hover {
        color: $color-acent;
      }
      svg {
        margin-right: 8px;
      }
    }
  }
}
