.vertical-menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* outline: thin solid red; */
}

.vertical-menu.collapsed {
  justify-content: center;
}

.open {
}

.vertical-menu ul {
  padding: 0;
}

.vertical-menu li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vertical-menu li a {
  text-decoration: none;
  text-align: left;
}

ul.vertical-menu {
}

ul.vertical-menu > li {
  color: #444545;
  font-size: 14px;
}
/* ul.vertical-menu > li:hover {
  background-color: #1ab394;
} */

ul.vertical-menu > li > header {
  /* outline: thin solid red; */

  border: 1px solid #e3e7e6;
  border-right: none;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.vertical-menu.collapsed > li > header {
  justify-content: center;
}

ul.vertical-menu > li > header:hover {
  /* background-color: #1ab394; */
  color: #e8f7f4;
}

ul.vertical-menu > li > ul {
  color: #444545;
}

ul.vertical-menu > li > ul > li {
  /* background-color: #e8f3f7; */
  border-bottom: 1px solid #e3e7e6;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
}

ul.vertical-menu > li > ul > li:hover {
  /* background-color: #1ab394; */
}

ul.vertical-menu > li > ul > li:hover > a {
  color: #e8f7f4;
}
ul.vertical-menu > li > ul > li:hover > .icon {
  color: #e8f7f4;
}

ul.vertical-menu > li > ul > li:last-of-type {
  border-bottom: none;
}

ul.vertical-menu > li > ul > li > a {
  padding: 1rem 1rem 1rem 1.5rem;
  color: #444545;
  width: 100%;
}

ul.vertical-menu > li > ul > li > a:active,
ul.vertical-menu > li > ul > li > a:focus {
  outline: 1px dashed #158f76;
}

ul.vertical-menu > li > ul > li > a:global(.active) {
  /* color: #17a185; */
}

ul.vertical-menu > li > ul > li:hover > a:global(.active) {
  color: #e8f7f4;
}
