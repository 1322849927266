// Copyright 2010 Google LLC
// Author: username

.role-table {
  // background-color: fuchsia;
}

.datagrid {
  // outline: thin dashed fuchsia;
  // height: calc(100vh - 280px);
  overflow-x: hidden;
}

.datagrid-header {
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.cell-permissions {
  text-align: right;
  display: inline-block;
  width: 100%;
  padding-right: 2rem;
}
