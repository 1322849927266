@import "/src/themes/origin-inca-theme";

.menu {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
    a {
      text-decoration: none;
      text-align: left;
      // color: $theme-color-text;

      width: 100%;
      padding: 1rem 1rem;

      &.active {
        // color: $theme-color-primary;
      }
      svg {
        margin-right: 8px;
        width: 24px;
      }
    }
  }
  > li {
    // color: #1ab394;
    color: $theme-color-text;
    font-size: 14px;

    border-bottom: 1px solid #e3e7e6;
    border-right: none;
    display: flex;
    justify-content: space-between;

    font-weight: 500;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      // background-color: #1ab394;
      a {
        color: #e8f7f4;
      }
    }
  }

  &.collapsed {
    justify-content: center;
    span {
      display: none;
    }
  }
}
