@import "/src/styles/screen-content-header";
@import "/src/styles/screen-content";

.screen-header {
  @include screen-content-header;
}

.screen-content {
  @include screen-content;
  padding-top: 1rem;
}

.table {
  // outline: thin dashed fuchsia;
  height: calc(100vh - 287px);
}
