// Copyright 2010 Google LLC
// Author: Alex Alvarez Gárciga

@import "/src/themes/origin-inca-theme";

.role-form {
  //   background-color: fuchsia;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: 321) {
    & > * {
      flex-basis: 50%;
    }
  }
}

.detail {
  border: thin solid $theme-color-control-border;
  padding: 1rem;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}
