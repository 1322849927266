$color-blueprint: #35637c;

$color-international-orange: #ff5000;

$color-international-orange-tint-10: #ff621a;
$color-international-orange-tint-20: #ff7333;
$color-international-orange-tint-30: #ff854d;
$color-international-orange-tint-40: #ff9666;
$color-international-orange-tint-50: #ffa880;
$color-international-orange-tint-60: #ffb999;
$color-international-orange-tint-70: #ffcbb3;
$color-international-orange-tint-80: #ffdccc;
$color-international-orange-tint-90: #ffeee6;

$color-international-orange-shade-10: #e64800;
$color-international-orange-shade-20: #cc4000;
$color-international-orange-shade-30: #b33800;
$color-international-orange-shade-40: #993000;
$color-international-orange-shade-50: #802800;
$color-international-orange-shade-60: #662000;
$color-international-orange-shade-70: #4c1800;
$color-international-orange-shade-80: #331000;
$color-international-orange-shade-90: #190800;

$color-chinese-black: #0e0815;

$color-chinese-black-tint-10: #26212c;
$color-chinese-black-tint-20: #3e3944;
$color-chinese-black-tint-30: #56525b;
$color-chinese-black-tint-40: #6e6b73;
$color-chinese-black-tint-50: #87848a;
$color-chinese-black-tint-60: #9f9ca1;
$color-chinese-black-tint-70: #b7b5b9;
$color-chinese-black-tint-80: #cfced0;
$color-chinese-black-tint-90: #e7e6e8;

$color-chinese-black-shade-10: #0d0713;
$color-chinese-black-shade-20: #0b0611;
$color-chinese-black-shade-30: #0a060f;
$color-chinese-black-shade-40: #08050d;
$color-chinese-black-shade-50: #07040b;
$color-chinese-black-shade-60: #060308;
$color-chinese-black-shade-70: #040206;
$color-chinese-black-shade-80: #030204;
$color-chinese-black-shade-90: #010102;

$color-jungle-green: #1ab394;

$color-jungle-green-tint-10: #31bb9f;
$color-jungle-green-tint-20: #48c2a9;
$color-jungle-green-tint-30: #5fcab4;
$color-jungle-green-tint-40: #76d1bf;
$color-jungle-green-tint-50: #8dd9ca;
$color-jungle-green-tint-60: #a3e1d4;
$color-jungle-green-tint-70: #bae8df;
$color-jungle-green-tint-80: #d1f0ea;
$color-jungle-green-tint-90: #e8f7f4;

$color-jungle-green-shade-10: #17a185;
$color-jungle-green-shade-20: #158f76;
$color-jungle-green-shade-30: #127d68;
$color-jungle-green-shade-40: #106b59;
$color-jungle-green-shade-50: #0d5a4a;
$color-jungle-green-shade-60: #0a483b;
$color-jungle-green-shade-70: #08362c;
$color-jungle-green-shade-80: #05241e;
$color-jungle-green-shade-90: #03120f;

$color-red-NCS: #b31a39;

$color-red-NCS-tint-10: #bb314d;
$color-red-NCS-tint-20: #c24861;
$color-red-NCS-tint-30: #ca5f74;
$color-red-NCS-tint-40: #d17688;
$color-red-NCS-tint-50: #d98d9c;
$color-red-NCS-tint-60: #e1a3b0;
$color-red-NCS-tint-70: #e8bac4;
$color-red-NCS-tint-80: #f0d1d7;
$color-red-NCS-tint-90: #f7e8eb;

$color-red-NCS-shade-10: #a11733;
$color-red-NCS-shade-20: #8f152e;
$color-red-NCS-shade-30: #7d1228;
$color-red-NCS-shade-40: #6b1022;
$color-red-NCS-shade-50: #5a0d1d;
$color-red-NCS-shade-60: #480a17;
$color-red-NCS-shade-70: #360811;
$color-red-NCS-shade-80: #24050b;
$color-red-NCS-shade-90: #120306;

$color-platinum: #e3e7e6;

$color-platinum-tint-10: #e6e9e9;
$color-platinum-tint-20: #e9eceb;
$color-platinum-tint-30: #ebeeee;
$color-platinum-tint-40: #eef1f0;
$color-platinum-tint-50: #f1f3f3;
$color-platinum-tint-60: #f4f5f5;
$color-platinum-tint-70: #f7f8f8;
$color-platinum-tint-80: #f9fafa;
$color-platinum-tint-90: #fcfdfd;

$color-platinum-shade-10: #ccd0cf;
$color-platinum-shade-20: #b6b9b8;
$color-platinum-shade-30: #9fa2a1;
$color-platinum-shade-40: #888b8a;
$color-platinum-shade-50: #727473;
$color-platinum-shade-60: #5b5c5c;
$color-platinum-shade-70: #444545;
$color-platinum-shade-80: #2d2e2e;
$color-platinum-shade-90: #171717;

$color-blue-NCS: #1a85b3;

$color-blue-NCS-tint-10: #3191bb;
$color-blue-NCS-tint-20: #489dc2;
$color-blue-NCS-tint-30: #5faaca;
$color-blue-NCS-tint-40: #76b6d1;
$color-blue-NCS-tint-50: #8dc2d9;
$color-blue-NCS-tint-60: #a3cee1;
$color-blue-NCS-tint-70: #badae8;
$color-blue-NCS-tint-80: #d1e7f0;
$color-blue-NCS-tint-90: #e8f3f7;

$color-blue-NCS-shade-10: #1778a1;
$color-blue-NCS-shade-20: #156a8f;
$color-blue-NCS-shade-30: #125d7d;
$color-blue-NCS-shade-40: #10506b;
$color-blue-NCS-shade-50: #0d435a;
$color-blue-NCS-shade-60: #0a3548;
$color-blue-NCS-shade-70: #082836;
$color-blue-NCS-shade-80: #051b24;
$color-blue-NCS-shade-90: #030d12;

$color-rajah: #f8ac59;

$color-rajah-tint-10: #f9b46a;
$color-rajah-tint-20: #f9bd7a;
$color-rajah-tint-30: #fac58b;
$color-rajah-tint-40: #fbcd9b;
$color-rajah-tint-50: #fcd6ac;
$color-rajah-tint-60: #fcdebd;
$color-rajah-tint-70: #fde6cd;
$color-rajah-tint-80: #feeede;
$color-rajah-tint-90: #fef7ee;

$color-rajah-shade-10: #df9b50;
$color-rajah-shade-20: #c68a47;
$color-rajah-shade-30: #ae783e;
$color-rajah-shade-40: #956735;
$color-rajah-shade-50: #7c562d;
$color-rajah-shade-60: #634524;
$color-rajah-shade-70: #4a341b;
$color-rajah-shade-80: #322212;
$color-rajah-shade-90: #191109;

$color-rust: #b3481a;

$color-rust-tint-10: #bb5a31;
$color-rust-tint-20: #c26d48;
$color-rust-tint-30: #ca7f5f;
$color-rust-tint-40: #d19176;
$color-rust-tint-50: #d9a48d;
$color-rust-tint-60: #e1b6a3;
$color-rust-tint-70: #e8c8ba;
$color-rust-tint-80: #f0dad1;
$color-rust-tint-90: #f7ede8;

$color-rust-shade-10: #a14117;
$color-rust-shade-20: #8f3a15;
$color-rust-shade-30: #7d3212;
$color-rust-shade-40: #6b2b10;
$color-rust-shade-50: #5a240d;
$color-rust-shade-60: #481d0a;
$color-rust-shade-70: #361608;
$color-rust-shade-80: #240e05;
$color-rust-shade-90: #120703;

$color-apple-green: #85b31a;

$color-apple-green-tint-10: #f3f7e8;
$color-apple-green-tint-20: #e7f0d1;
$color-apple-green-tint-30: #dae8ba;
$color-apple-green-tint-40: #cee1a3;
$color-apple-green-tint-50: #c2d98d;
$color-apple-green-tint-60: #b6d176;
$color-apple-green-tint-70: #aaca5f;
$color-apple-green-tint-80: #9dc248;
$color-apple-green-tint-90: #91bb31;

$color-apple-green-shade-10: #78a117;
$color-apple-green-shade-20: #6a8f15;
$color-apple-green-shade-30: #5d7d12;
$color-apple-green-shade-40: #506b10;
$color-apple-green-shade-50: #435a0d;
$color-apple-green-shade-60: #35480a;
$color-apple-green-shade-70: #283608;
$color-apple-green-shade-80: #1b2405;
$color-apple-green-shade-90: #0d1203;
