/*
 * Spacing
 */

/*
 * Use a spacing of 0 pixels
 */
$token-spacing-space-0: 0 !default;
/*
 * Use a spacing of 1 pixels
*/
$token-spacing-space-025: 0.0625rem !default;
/*
 * Use a spacing of 2 pixels
*/
$token-spacing-space-05: 0.125rem !default;
/*
 * Use a spacing of 4 pixels
*/
$token-spacing-space-1: 0.25rem !default;
/*
 *Use a spacing of 8 pixels
*/
$token-spacing-space-2: 0.5rem !default;
/*
 *	Use a spacing of 12 pixels
*/
$token-spacing-space-3: 0.75rem !default;
/*
 *	Use a spacing of 16 pixels
*/
$token-spacing-space-4: 1rem !default;
/*
 *	Use a spacing of 20 pixels
*/
$token-spacing-space-5: 1.25rem !default;
/*
 *	Use a spacing of 24 pixels
*/
$token-spacing-space-6: 1.5rem !default;
/*
 *	Use a spacing of 32 pixels
*/
$token-spacing-space-8: 2rem !default;
/*
 *	Use a spacing of 40 pixels
*/
$token-spacing-space-10: 2.5rem !default;
/*
 *	Use a spacing of 48 pixels
*/
$token-spacing-space-12: 3rem !default;
/*
 *	Use a spacing of 64 pixels
*/
$token-spacing-space-16: 4rem !default;
/*
 *	Use a spacing of 80 pixels
*/
$token-spacing-space-20: 5rem !default;
/*
 *	Use a spacing of 96 pixels
*/
$token-spacing-space-24: 6rem !default;
/*
 *	Use a spacing of 112 pixels
*/
$token-spacing-space-28: 7rem !default;
/*
 *	Use a spacing of 128 pixels
*/
$token-spacing-space-32: 8rem !default;

/*
 * Font
 */

$token-font-family-sans: "Lato", "Segoe UI", "Open Sans", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  -apple-system, BlinkMacSystemFont, sans-serif;

/*
 Use typography styles from UI kit
 */
$token-font-size-75: 0.75rem;
$token-font-size-100: 0.875rem;
$token-font-size-200: 1rem;
$token-font-size-300: 1.25rem;
$token-font-size-400: 1.5rem;
$token-font-size-500: 1.75rem;
$token-font-size-600: 2rem;
$token-font-size-700: 2.5rem;

$token-font-weight-regular: 400;
$token-font-weight-medium: 500;
$token-font-weight-semibold: 600;
$token-font-weight-bold: 700;

$token-font-line-height-1: 1rem;
$token-font-line-height-2: 1.25rem;
$token-font-line-height-3: 1.5rem;
$token-font-line-height-4: 1.75rem;
$token-font-line-height-5: 2rem;
$token-font-line-height-6: 2.5rem;
$token-font-line-height-7: 3rem;

/*
 * Colors
 */

/*
 * Use Background/Default	
 * For use as a background color, in components such as Page and Frame backgrounds.
 */
$token-color-background: rgba(246, 246, 247, 1) !default;

/*
* Use Background/Hovered	
* For use when an action or navigation is used on a background.
*/
$token-color-background-hovered: rgba(241, 242, 243, 1) !default;
/*
*Use Background/Pressed	
*For use when an action or navigation is used on a background.
*/
$token-color-background-pressed: rgba(237, 238, 239, 1) !default;
/*
*Use Background/Selected	
*For use in the selected item in navigation
*/
$token-color-background-selected: rgba(237, 238, 239, 1) !default;

/*
*For use as a background color, in components such as Card, Modal, and Popover.
*/
$token-color-surface: rgba(255, 255, 255, 1) !default;

/*
*For use as a dark background color, in components such as Card, Modal, and Popover.
*/
$token-color-surface-dark: rgba(32, 33, 35, 1) !default;
/*
*Use Surface Neutral/Default	
*For use as a background color in neutral badges.
*/
$token-color-surface-neutral: rgba(228, 229, 231, 1) !default;
/*
*Use Surface Neutral/Hovered	
*For use as a hovered background color in neutral badges.
*/
$token-color-surface-neutral-hovered: rgba(219, 221, 223, 1) !default;
/*
*Use Surface Neutral/Pressed	
*For use as a pressed background color in neutral badges.
*/
$token-color-surface-neutral-pressed: rgba(201, 204, 208, 1) !default;
/*
*Use Surface Neutral/Disabled	
*For use as a disabled background color in neutral badges.
*/
$token-color-surface-neutral-disabled: rgba(241, 242, 243, 1) !default;
/*
*Use Surface Neutral/Subdued	
*For use as a background color in neutral banners.
*/
$token-color-surface-neutral-subdued: rgba(246, 246, 247, 1) !default;
/*
*For use as a dark background color in neutral banners.
*/
$token-color-surface-neutral-subdued-dark: rgba(68, 71, 74, 1) !default;
/*
*Use Surface/Subdued	
*For use as a subdued background color, in components such as Card, Modal, and Popover.
*/
$token-color-surface-subdued: rgba(250, 251, 251, 1) !default;
/*
*Use Surface/Disabled	
*For use as a surface color on disabled interactive elements such as option list items and action list items when in a disabled state.
*/
$token-color-surface-disabled: rgba(250, 251, 251, 1) !default;
/*
*Use Surface/Hovered	
*For use as a surface color on interactive elements such as resource list items and action list items when in a hovered state.
*/
$token-color-surface-hovered: rgba(246, 246, 247, 1) !default;

/*
*For use as a dark surface color on interactive elements such as resource list items and action list items when in a hovered state.
*/
$token-color-surface-hovered-dark: rgba(47, 49, 51, 1) !default;
/*
*Use Surface/Pressed	
*For use as a surface color on interactive elements such as resource list items and action list items when in a pressed state.
*/
$token-color-surface-pressed: rgba(241, 242, 243, 1) !default;
/*
*For use as a dark surface color on interactive elements such as resource list items and action list items when in a pressed state.
*/
$token-color-surface-pressed-dark: rgba(62, 64, 67, 1) !default;
/*
*Use Surface/Depressed
*For use as a surface color on interactive elements such as resource list items and action list items when in a depressed state.
*/
$token-color-surface-depressed: rgba(237, 238, 239, 1) !default;
/*
*Use Surface/SearchField	For use as a background color, in components on surface elements such as SearchField
*/
$token-color-surface-search-field: rgba(241, 242, 243, 1) !default;
/*
*For use as a dark background color, in components on surface elements such as SearchField
*/
$token-color-surface-search-field-dark: rgba(47, 49, 51, 1) !default;
/*
For use as the background color of the backdrop component for navigation and modal. This color has an alpha of `0.5`.
*/
$token-color-backdrop: rgba(0, 0, 0, 0.5) !default;
/*
For use as the background color of elements which lay on top of surfaces to obscure their contents. This color has an alpha of `0.5`.
*/
$token-color-overlay: rgba(255, 255, 255, 0.5) !default;
/*

*/
$token-color-shadow-color-picker: rgba(0, 0, 0, 0.5) !default;

/*

*/
$token-color-shadow-color-picker-dragger: rgba(33, 43, 54, 0.32) !default;
/*
Use Hint/From direct light	
For use in building shadows scrollables.
*/
$token-color-hint-from-direct-light: rgba(0, 0, 0, 0.15) !default;
/*  
For use as the default border on elements.
*/
$token-color-border: rgba(140, 145, 150, 1) !default;
/*
For use as the default border on dark elements.
*/
$token-color-border-on-dark: rgba(80, 83, 86, 1) !default;
/*
Use Border Neutral/Subdued	
For use as the border on banners.
*/
$token-color-border-neutral-subdued: rgba(186, 191, 195, 1) !default;
/*
Use Border/Hovered	
Used for borders on hovered interactive elements
*/
$token-color-border-hovered: rgba(153, 158, 164, 1) !default;
/*
Use Border/Disabled	
Used for disabled borders on interactive elements
*/
$token-color-border-disabled: rgba(210, 213, 216, 1) !default;
/*
Use Border/Subdued	
For use as a subdued border on elements.
*/
$token-color-border-subdued: rgba(201, 204, 207, 1) !default;
/*
Use Border/Depressed	
For use as a border on depressed elements.
*/
$token-color-border-depressed: rgba(87, 89, 89, 1) !default;
/*
Use BorderShadow	
For use as an additional bottom border on elements.
*/
$token-color-border-shadow: rgba(174, 180, 185, 1) !default;

/*
Use BorderShadow/Subdued	
For use as an additional, subdued bottom border on elements.
*/
$token-color-border-shadow-subdued: rgba(186, 191, 196, 1) !default;
/*
For use as a divider between elements.
*/
$token-color-divider: rgba(225, 227, 229, 1) !default;
/*
For use as a dark divider between elements.
*/
$token-color-divider-dark: rgba(69, 71, 73, 1) !default;
/*
For use as the fill color of icons.
*/
$token-color-icon: rgba(92, 95, 98, 1) !default;
/*
For use as the fill color of dark icons.
*/
$token-color-icon-on-dark: rgba(166, 172, 178, 1) !default;
/*
Use Icon/Hovered	
For use as the fill color of hovered icons.
*/
$token-color-icon-hovered: rgba(26, 28, 29, 1) !default;
/*
Use Icon/Pressed	
For use as the fill color of pressed icons.
*/
$token-color-icon-pressed: rgba(68, 71, 74, 1) !default;
/*
Use Icon/Disabled	
For use as the fill color of disabled icons.
*/
$token-color-icon-disabled: rgba(186, 190, 195, 1) !default;
/*
Use Icon/Subdued	
For use as the fill color of subdued icons.
*/
$token-color-icon-subdued: rgba(140, 145, 150, 1) !default;
/*
For use as a text color.
*/
$token-color-text: rgba(32, 34, 35, 1) !default;
/*
For use as a text color on dark elements.
*/
$token-color-text-on-dark: rgba(227, 229, 231, 1) !default;
/*
Use Text/Disabled	
For use as a disabled text color and as a placeholder text color.
*/
$token-color-text-disabled: rgba(140, 145, 150, 1) !default;
/*
Use Text/Subdued	
For use as a subdued text color.
*/
$token-color-text-subdued: rgba(109, 113, 117, 1) !default;
/*
For use as a subdued text color on dark elements.
*/
$token-color-text-subdued-on-dark: rgba(153, 159, 164, 1) !default;
/*
Used for links, plain buttons, and as the fill color for selected checkboxes and radio buttons.
*/
$token-color-interactive: rgba(44, 110, 203, 1) !default;
/*
Used for links, plain buttons, and as the fill color for selected checkboxes and radio buttons when on a dark element.
*/
$token-color-interactive-on-dark: rgba(54, 163, 255, 1) !default;
/*
Use Interactive/Disabled	Used for disabled links and plain buttons.
*/
$token-color-interactive-disabled: rgba(189, 193, 204, 1) !default;
/*
Use Interactive/Hovered	
Used for hovered links and plain buttons.
*/
$token-color-interactive-hovered: rgba(31, 81, 153, 1) !default;
/*
Use Interactive/Pressed	Used for pressed links and plain buttons.
*/
$token-color-interactive-pressed: rgba(16, 50, 98, 1) !default;
/*
Used for pressed links and plain buttons on dark elements.
*/
$token-color-interactive-pressed-on-dark: rgba(136, 188, 255, 1) !default;
/*
For use in the focus ring on interactive elements.
*/
$token-color-focused: rgba(69, 143, 255, 1) !default;
/*
Use Surface Selected/Default	
For use as a surface color in selected interactive elements, in components such as action list and resource list.
*/
$token-color-surface-selected: rgba(242, 247, 254, 1) !default;
/*
Use Surface Selected/Hovered	
For use as a surface color in selected interactive elements that are hovered, in components such as action list and resource list.
*/
$token-color-surface-selected-hovered: rgba(237, 244, 254, 1) !default;
/*
Use Surface Selected/Pressed	
For use as a surface color in selected interactive elements that are pressed, in components such as action list and resource list.
*/
$token-color-surface-selected-pressed: rgba(229, 239, 253, 1) !default;
/*
Use Icon On/Interactive	
For use as a fill color for icons on interactive elements.
*/
$token-color-icon-on-interactive: rgba(255, 255, 255, 1) !default;
/*
Use Text On/Interactive	
For use as a text color on interactive elements.
*/
$token-color-text-on-interactive: rgba(255, 255, 255, 1) !default;
/*
Use Action Secondary/Default	
Used for secondary buttons and tertiary buttons, as well as in form elements as a background color and pontentially other secondary surfaces.
*/
$token-color-action-secondary: rgba(255, 255, 255, 1) !default;
/*
Use Action Secondary/Disabled	
Used as a disabled state for secondary buttons
*/
$token-color-action-secondary-disabled: rgba(255, 255, 255, 1) !default;
/*
Use Action Secondary/Hovered	
Used as a hovered state for secondary buttons
*/
$token-color-action-secondary-hovered: rgba(246, 246, 247, 1) !default;
/*
Used as a dark hovered state for secondary buttons
*/
$token-color-action-secondary-hovered-dark: rgba(84, 87, 91, 1) !default;
/*
Use Action Secondary/Pressed	
Used as a pressed state for secondary buttons
*/
$token-color-action-secondary-pressed: rgba(241, 242, 243, 1) !default;
/*
Used as a dark pressed state for secondary buttons
*/
$token-color-action-secondary-pressed-dark: rgba(96, 100, 103, 1) !default;
/*
Use Action Secondary/Depressed	
Used as a depressed state for secondary buttons
*/
$token-color-action-secondary-depressed: rgba(109, 113, 117, 1) !default;
/*
Use Action Primary/Default	
Used as the background color for primary actions, and as the fill color for icons and the text color in navigation and tabs to communicate interaction states.
*/
$token-color-action-primary: rgba(0, 128, 96, 1) !default;
/*
Use Action Primary/Disabled	
Used as the background color for disabled primary actions, and as the fill color for icons and the text color in navigation and tabs to communicate interaction states.
*/
$token-color-action-primary-disabled: rgba(241, 241, 241, 1) !default;
/*
Use Action Primary/Hovered	Used as the background color for hovered primary actions, and as the fill color for icons and the text color in navigation and tabs to communicate interaction states.
*/
$token-color-action-primary-hovered: rgba(0, 110, 82, 1) !default;
/*
Use Action Primary/Pressed	
Used as the background color for pressed primary actions, and as the fill color for icons and the text color in navigation and tabs to communicate interaction states.
*/
$token-color-action-primary-pressed: rgba(0, 94, 70, 1) !default;
/*
Use Action Primary/Depressed	
Used as the background color for pressed primary actions, and as the fill color for icons and the text color in navigation and tabs to communicate interaction states.
*/
$token-color-action-primary-depressed: rgba(0, 61, 44, 1) !default;
/*
Use Icon On/Primary	
For use as a fill color for icons on primary actions. Not for use in icons on navigation and tabs.
*/
$token-color-icon-on-primary: rgba(255, 255, 255, 1) !default;
/*
Use Text On/Primary	
For use as a text color on primary actions. Not for use in text on navigation and tabs.
*/
$token-color-text-on-primary: rgba(255, 255, 255, 1) !default;
/*
Use Text Primary/Default	
For use as primary text color on background. For use in text in components such as Navigation.
*/
$token-color-text-primary: rgba(0, 123, 92, 1) !default;
/*
Use Text Primary/hover	
For use as primary hovered text color on background. For use in text in components such as Navigation.
*/
$token-color-text-primary-hovered: rgba(0, 108, 80, 1) !default;
/*
Use Text Primary/Pressed	
For use as primary pressed text color on background. For use in text in components such as Navigation.
*/
$token-color-text-primary-pressed: rgba(0, 92, 68, 1) !default;
/*
Use Surface Primary/Selected	
Used as a surface color to indicate selected interactive states in navigation and tabs.
*/
$token-color-surface-primary-selected: rgba(241, 248, 245, 1) !default;
/*
Use Surface Primary/Selected Hovered	
Used as a surface color to indicate selected interactive states that are hovered in navigation and tabs.
*/
$token-color-surface-primary-selected-hovered: rgba(179, 208, 195, 1) !default;
/*
Use Surface Primary/Selected Pressed	
Used as a surface color to indicate selected interactive states that are pressed in navigation and tabs.
*/
$token-color-surface-primary-selected-pressed: rgba(162, 188, 176, 1) !default;
/*
Use Border Critical/Default	
For use as a border on critical components such as an outline on interactive elements in an error state.
*/
$token-color-border-critical: rgba(253, 87, 73, 1) !default;
/*
Use Border Critical/Subdued	
For use as a border on critical components such as banners.
*/
$token-color-border-critical-subdued: rgba(224, 179, 178, 1) !default;
/*
Use Border Critical/Disabled
For use as a disabled border on critical components such as banners, and as an outline on interactive elements in an error state.
*/
$token-color-border-critical-disabled: rgba(255, 167, 163, 1) !default;
/*
Use Icon/Critical	
For use as an icon fill color on top of critical elements.
*/
$token-color-icon-critical: rgba(215, 44, 13, 1) !default;
/*
Use Surface Critical/Default	
For use as a surface color on critical elements including badges.
*/
$token-color-surface-critical: rgba(254, 211, 209, 1) !default;
/*
Use Surface Critical/Subdued	
For use as a subdued surface color on critical elements including banners.
*/
$token-color-surface-critical-subdued: rgba(255, 244, 244, 1) !default;
/*
Use Surface Critical/Subdued Hovered	
For use as a surface color on critical interactive elements including action list items in a hovered state.
*/
$token-color-surface-critical-subdued-hovered: rgba(255, 240, 240, 1) !default;
/*
Use Surface Critical/Subdued Pressed	
For use as a surface color on critical interactive elements including action list items in a pressed state.
*/
$token-color-surface-critical-subdued-pressed: rgba(255, 233, 232, 1) !default;
/*
Use Surface Critical/Subdued Depressed	
For use as a surface color on critical interactive elements including action list items in a depressed state.
*/
$token-color-surface-critical-subdued-depressed: rgba(
  254,
  188,
  185,
  1
) !default;
/*
Use Text/Critical	
For use as a text color in inert critical elements such as exception list. Not for use as a text color on banners and badges.
*/
$token-color-text-critical: rgba(215, 44, 13, 1) !default;
/*
Use Action Critical/Default	
For use as the background color for destructive buttons, and as the background color for error toast messages.
*/
$token-color-action-critical: rgba(216, 44, 13, 1) !default;
/*
Use Action Critical/Disabled	
For use as the background color for disabled destructive buttons, and as the background color for error toast messages.
*/
$token-color-action-critical-disabled: rgba(241, 241, 241, 1) !default;
/*
Use Action Critical/Hovered	
For use as the background color for hovered destructive buttons, and as the background color for error toast messages.
*/
$token-color-action-critical-hovered: rgba(188, 34, 0, 1) !default;
/*
Use Action Critical/Pressed	
For use as the background color for pressed destructive buttons, and as the background color for error toast messages.
*/
$token-color-action-critical-pressed: rgba(162, 27, 0, 1) !default;
/*
Use Action Critical/Depressed	
For use as the background color for depressed destructive buttons, and as the background color for error toast messages.
*/
$token-color-action-critical-depressed: rgba(108, 15, 0, 1) !default;
/*
Use Icon On/Critical	
For use as a fill color for icons on critical actions.
*/
$token-color-icon-on-critical: rgba(255, 255, 255, 1) !default;
/*
Use Text On/Critical	
For use as a text color on critical actions.
*/
$token-color-text-on-critical: rgba(255, 255, 255, 1) !default;
/*
Use Interactive/Critical	
For use as the text color for destructive interactive elements: links, plain buttons, error state of selected checkboxes and radio buttons, as well as a text color on destructive action list items. Not for use on critical banners and badges.
*/
$token-color-interactive-critical: rgba(216, 44, 13, 1) !default;
/*
Use Interactive/Critical Disabled	
For use as a text color in disabled destructive plain buttons, as well as a text color on destructive action list items. Not for use on critical banners and badges.
*/
$token-color-interactive-critical-disabled: rgba(253, 147, 141, 1) !default;
/*
Use Interactive/Critical Hovered	
For use as a text color in hovered destructive plain buttons, as well as a text color on destructive action list items. Not for use on critical banners and badges.
*/
$token-color-interactive-critical-hovered: rgba(205, 41, 12, 1) !default;
/*
Use Interactive/Critical Pressed	
For use as a text color in pressed destructive plain buttons, as well as a text color on destructive action list items. Not for use on critical banners and badges.
*/
$token-color-interactive-critical-pressed: rgba(103, 15, 3, 1) !default;
/*
Use Border Warning/Default	
For use as a border on warning components such as...
*/
$token-color-border-warning: rgba(185, 137, 0, 1) !default;
/*
Use Border Warning/Subdued	
For use as a border on warning components such as banners.
*/
$token-color-border-warning-subdued: rgba(225, 184, 120, 1) !default;
/*
Use Icon/Warning	
For use as an icon fill color on top of warning elements.
*/
$token-color-icon-warning: rgba(185, 137, 0, 1) !default;
/*
Use Surface Warning/Default	
For use as a surface color on warning elements including badges.
*/
$token-color-surface-warning: rgba(255, 215, 157, 1) !default;
/*
Use Surface Warning/Subdued	
For use as a subdued surface color on warning elements including banners.
*/
$token-color-surface-warning-subdued: rgba(255, 245, 234, 1) !default;
/*
Use Surface Warning/Subdued 
Hovered	For use as a subdued surface color on warning elements including banners.
*/
$token-color-surface-warning-subdued-hovered: rgba(255, 242, 226, 1) !default;
/*
Use Surface Warning/Subdued 
Pressed	For use as a subdued surface color on warning elements including banners.
*/
$token-color-surface-warning-subdued-pressed: rgba(255, 235, 211, 1) !default;
/*
Use Text/Warning	
For use as a text color in inert critical elements such as exception list. Not for use as a text color on banners and badges.
*/
$token-color-text-warning: rgba(145, 106, 0, 1) !default;
/*
Use Border Highlight/Default	
For use as a border on informational components such as...
*/
$token-color-border-highlight: rgba(68, 157, 167, 1) !default;
/*
Use Border Highlight/Subdued	
For use as a border on informational components such as banners.
*/
$token-color-border-highlight-subdued: rgba(152, 198, 205, 1) !default;
/*
Use Icon/Highlight	
For use as an icon fill color on top of informational elements.
*/
$token-color-icon-highlight: rgba(0, 160, 172, 1) !default;
/*
Use Surface Highlight/Default	
For use as a surface color on information elements including badges.
*/
$token-color-surface-highlight: rgba(164, 232, 242, 1) !default;
/*
Use Surface Highlight/Subdued	
For use as a surface color on information elements including banners.
*/
$token-color-surface-highlight-subdued: rgba(235, 249, 252, 1) !default;
/*
Use Surface Highlight/Subdued Hovered	
For use as a surface color on information elements including banners.
*/
$token-color-surface-highlight-subdued-hovered: rgba(228, 247, 250, 1) !default;
/*
Use Surface Highlight/Subdued Pressed	
For use as a surface color on information elements including banners.
*/
$token-color-surface-highlight-subdued-pressed: rgba(213, 243, 248, 1) !default;
/*
Use Text/Highlight	
For use as a text color in inert informational elements. Not for use as a text color on banners and badges.
*/
$token-color-text-highlight: rgba(52, 124, 132, 1) !default;
/*
Use Border Success/Default	
For use as a border on success components such as text inputs.
*/
$token-color-border-success: rgba(0, 164, 124, 1) !default;
/*
Use Border Success/Subdued	
For use as a border on success components such as banners.
*/
$token-color-border-success-subdued: rgba(149, 201, 180, 1) !default;
/*
Use Icon/Success	
For use as an icon fill color on top of success elements.
*/
$token-color-icon-success: rgba(0, 127, 95, 1) !default;
/*
Use Surface Success/Default	
For use as a surface color on success elements including badges.
*/
$token-color-surface-success: rgba(174, 233, 209, 1) !default;
/*
Use Surface Success/Subdued	
For use as a surface color on information elements including banners.
*/
$token-color-surface-success-subdued: rgba(241, 248, 245, 1) !default;
/*
Use Surface Success/Subdued 
Hovered	For use as a surface color on information elements including banners.
*/
$token-color-surface-success-subdued-hovered: rgba(236, 246, 241, 1) !default;
/*
Use Surface Success/Subdued 
Pressed	For use as a surface color on information elements including banners.
*/
$token-color-surface-success-subdued-pressed: rgba(226, 241, 234, 1) !default;
/*
Use Text/Success	
For use as a text color in inert success elements. Not for use as a text color on banners and badges.
*/
$token-color-text-success: rgba(0, 128, 96, 1) !default;
/*

*/
$token-color-icon-attention: rgba(138, 97, 22, 1) !default;
/*

*/
$token-color-surface-attention: rgba(255, 234, 138, 1) !default;
/*
Use Decorative/Icon/One	
For use as a decorative icon color that is applied on a decorative surface.
*/
$token-color-decorative-one-icon: rgba(126, 87, 0, 1) !default;

/*
Use Decorative/Surface/One	
For use as a decorative surface color.
*/
$token-color-decorative-one-surface: rgba(255, 201, 107, 1) !default;
/*
Use Decorative/Text/One	
For use as a decorative text color that is applied on a decorative surface.
*/
$token-color-decorative-one-text: rgba(61, 40, 0, 1) !default;
/*
Use Decorative/Icon/Two	
For use as a decorative icon color that is applied on a decorative surface.
*/
$token-color-decorative-two-icon: rgba(175, 41, 78, 1) !default;
/*
Use Decorative/Surface/Two	
For use as a decorative surface color.
*/
$token-color-decorative-two-surface: rgba(255, 196, 176, 1) !default;
/*
Use Decorative/Text/Two	
For use as a decorative text color that is applied on a decorative surface.
*/
$token-color-decorative-two-text: rgba(73, 11, 28, 1) !default;
/*
Use Decorative/Icon/Three	
For use as a decorative icon color that is applied on a decorative surface.
*/
$token-color-decorative-three-icon: rgba(0, 109, 65, 1) !default;
/*
Use Decorative/Surface/Three	
For use as a decorative surface color.
*/
$token-color-decorative-three-surface: rgba(146, 230, 181, 1) !default;
/*
Use Decorative/Text/Three	
For use as a decorative text color that is applied on a decorative surface.
*/
$token-color-decorative-three-text: rgba(0, 47, 25, 1) !default;
/*
Use Decorative/Icon/Four	
For use as a decorative icon color that is applied on a decorative surface.
*/
$token-color-decorative-four-icon: rgba(0, 106, 104, 1) !default;
/*
Use Decorative/Surface/Four	
For use as a decorative surface color.
*/
$token-color-decorative-four-surface: rgba(145, 224, 214, 1) !default;
/*
Use Decorative/Text/Four	
For use as a decorative text color that is applied on a decorative surface.
*/
$token-color-decorative-four-text: rgba(0, 45, 45, 1) !default;
/*
Use Decorative/Icon/Five	
For use as a decorative icon color that is applied on a decorative surface.
*/
$token-color-decorative-five-icon: rgba(174, 43, 76, 1) !default;
/*
Use Decorative/Surface/Five	
For use as a decorative surface color.
*/
$token-color-decorative-five-surface: rgba(253, 201, 208, 1) !default;
/*
Use Decorative/Text/Five	
For use as a decorative text color that is applied on a decorative surface.
*/
$token-color-decorative-five-text: rgba(79, 14, 31, 1) !default;

/*
 * Current theme variation
 */
@import "/src/themes/origin-inca-theme";
// @import "/src/themes/svz-theme";
