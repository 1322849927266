.card-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.menu-category {
  width: 100%;
  margin: 1.5rem 1rem 1rem 1rem;
  color: #444545;
  font-weight: 600;
  padding-bottom: 0.5rem;

  border-bottom: 1px solid #158f76;
  border-bottom: 1px solid #e3e7e6;
}
.loading {
  padding: 0 1rem;
}
